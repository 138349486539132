import { ready } from "./ready";

const COOKIE_BANNER_CLOSE_ANIMATION_DURATION_MS = 500;
const COOKIE_PRO_BANNER_ID = "onetrust-banner-sdk";
const COOKIE_PRO_FLOATING_BUTTON_ID = "ot-sdk-btn-floating";
const COOKIE_PRO_OPEN_BUTTON_CLASS = "ot-floating-button__open";
const COOKIE_PRO_PREFERENCE_CENTER_CURRENTLY_OPEN_CLASS = "ot-hide";
const PREFERENCE_CENTER_BUTTON_SELECTOR = ".js-open-preference-center";

type ScriptLoader = () => void;

interface Config {
    cookieProScriptId: string;
    isProduction: boolean;
    showBanner?: boolean;
    onLoadedCallback?: () => void;
}

let IS_LOADED = false;
const scriptsToLoad = new Map<ScriptLoader, CookieConsentCategory>();

/**
 * Cookie Consent categories as defined in CookiePro, the third party provider used for managing cookie consent
 * https://app.cookiepro.com/cookies/categorizations?tab=Categories
 */
export enum CookieConsentCategory {
    Essential = "C0001",
    Performance = "C0002",
    Functional = "C0003",
    Targeting = "C0004",
    SocialMedia = "C0005",
}

/**
 * This loads certain scripts if the user has consented to the cookies
 * the particular script will use.
 *
 * e.g.:
 *  window.loadScriptCookieSafe("C0004", "//script.name.com/script.js")
 *  window.loadScriptCookieSafe("C0003", () => { [some code to run] })
 */
export function loadScriptCookieSafe(cookieCode: CookieConsentCategory, scriptLoader: ScriptLoader) {
    scriptsToLoad.set(scriptLoader, cookieCode);
}

export function loadCookieConsent(config: Config) {
    // store reference to any existing OptanonWrapper function
    const existingOptanonWrapper = window.OptanonWrapper;

    // CookiePro calls this global function initially to report the user's consent
    // and then again when the user changes their consent.
    window.OptanonWrapper = function OptanonWrapper() {
        if (!IS_LOADED) {
            IS_LOADED = true;

            // if explicitly hiding the banner
            if (config.showBanner === false) {
                hideCookieBanner();
            }

            // first time function is called so execute callback
            if (config.onLoadedCallback) {
                config.onLoadedCallback();
            }
        }

        const categoryCodes = window.OptanonActiveGroups.split(",").filter((code) => code !== "");
        function isCookieCategoryConsented(code: string) {
            return categoryCodes.indexOf(code) >= 0;
        }

        scriptsToLoad.forEach((code: string, script: ScriptLoader) => {
            if (isCookieCategoryConsented(code)) {
                runScript(script);
            }
        });

        if (existingOptanonWrapper) {
            existingOptanonWrapper();
        }
    };

    loadCookieProSDK(config.cookieProScriptId, config.isProduction);

    attachPreferenceCenterButtons();
}

function attachPreferenceCenterButtons() {
    ready(() => {
        document.querySelectorAll(PREFERENCE_CENTER_BUTTON_SELECTOR).forEach((button) => {
            button.addEventListener("click", openPreferenceCenter);
        });
    });
}

function hideCookieBanner() {
    document.getElementById("onetrust-consent-sdk")?.classList.add("js-cookie-consent-hide");
}

function loadCookieProSDK(cookieProScriptId: string, production: boolean) {
    createScript(`//cookie-cdn.cookiepro.com/consent/${cookieProScriptId}${production ? "" : "-test"}/otSDKStub.js`, {
        "data-language": "en",
        "data-domain-script": cookieProScriptId + (production ? "" : "-test"),
    });
}

function runScript(script: ScriptLoader) {
    if (typeof script === "function") {
        script();
    } else {
        createScript(script);
    }
}

function createScript(src: string, attrs: any = {}) {
    const element = document.createElement("script");
    element.setAttribute("src", src);
    Object.keys(attrs).forEach(function (key) {
        element.setAttribute(key, attrs[key]);
    });
    document.head.appendChild(element);
}

// Duplicated in ynab_web/app/lib/cookieConsent.ts
export function openPreferenceCenter() {
    if (!window.OneTrust) {
        return;
    }

    if (hasCookieBanner() && isCookieBannerOpen()) {
        closeCookieBanner(openPreferenceCenter);
        return;
    }

    if (hasFloatingButton()) {
        openPreferenceCenterFromFloatingButton();
        return;
    }

    openPreferenceCenterFromSDK();
}

function hasCookieBanner() {
    // only some of our CookiePro templates have the cookie banner
    return !!document.getElementById(COOKIE_PRO_BANNER_ID);
}

function hasFloatingButton() {
    // only some of our CookiePro templates have the floating button
    return !!document.getElementById(COOKIE_PRO_FLOATING_BUTTON_ID);
}

function isCookieBannerOpen() {
    return window.OneTrust?.IsAlertBoxClosed() === false;
}

function closeCookieBanner(onClosedCallback: () => void) {
    window.OneTrust?.Close();
    setTimeout(onClosedCallback, COOKIE_BANNER_CLOSE_ANIMATION_DURATION_MS);
}

function openPreferenceCenterFromFloatingButton() {
    const floatingButton = document.getElementById(COOKIE_PRO_FLOATING_BUTTON_ID);

    if (floatingButton?.classList.contains(COOKIE_PRO_PREFERENCE_CENTER_CURRENTLY_OPEN_CLASS)) {
        return;
    }

    // we simulate clicking the button instead of using window.OneTrust.ToggleInfoDisplay()
    // (see https://my.onetrust.com/s/article/UUID-d8291f61-aa31-813a-ef16-3f6dec73d643)
    // because using ToggleInfoDisplay does not wire up ESC key to close the preferences
    // center or display the close button's icon correctly
    (document.getElementsByClassName(COOKIE_PRO_OPEN_BUTTON_CLASS)[0] as HTMLElement)?.click();
}

function openPreferenceCenterFromSDK() {
    window.OneTrust?.ToggleInfoDisplay();
}
