/**
 * Call `fn` when the DOM is ready. If the DOM is already loaded, call `fn` immediately.
 * @param fn The function to call when the DOM is ready.
 */
export function ready(fn: () => void) {
    if (document.readyState !== "loading") {
        fn();
    } else {
        document.addEventListener("DOMContentLoaded", fn);
    }
}
