export namespace ConsoleUtilities {
    export enum LogLevels {
        Error = 0,
        Warning,
        Info,
        Debug,
        Trace,
    }

    const timestampMessages = true;

    let _silent: boolean = false;
    let _logLevel: LogLevels = LogLevels.Error;

    export function mute(): void {
        _silent = true;
    }
    export function unMute(): void {
        _silent = false;
    }

    export function getLogLevel(): LogLevels {
        return _logLevel;
    }
    export function setLogLevel(value: LogLevels): void {
        _logLevel = value;
    }

    export function error(message: string | (() => string) | Error, ...otherParams: any[]): void {
        log(LogLevels.Error, console.error, "color:red", message, ...otherParams);
    }

    export function warning(message: string | (() => string), ...otherParams: any[]): void {
        log(LogLevels.Warning, console.warn, "color:orange", message, ...otherParams);
    }

    export function info(message: string | (() => string), ...otherParams: any[]): void {
        log(LogLevels.Info, console.info, "color:green", message, ...otherParams);
    }

    export function debug(message: string | (() => string), ...otherParams: any[]): void {
        log(LogLevels.Debug, console.debug, "color:black", message, ...otherParams);
    }

    export function trace(message: string | (() => string), ...otherParams: any[]): void {
        log(LogLevels.Trace, console.trace, "color:black", message, ...otherParams);
    }

    function log(
        logLevel: LogLevels,
        target: (message?: any, ...optionalParams: any[]) => void,
        color: string,
        message: string | (() => string) | Error,
        ...otherParams: any[]
    ): void {
        if (_silent == true || getLogLevel() < logLevel) {
            return;
        }

        let addColor = false;

        if (!target) {
            // If current environment does not implement target (i.e. console.error), fallback to console.log() and add color
            target = console.log;
            addColor = true;
        }

        if (message instanceof Error) {
            // If message is an Error, do not change it because otherwise we will lose the stack info
            target.apply(console, [message, ...otherParams]);
        } else {
            let logOutput = "";

            if (typeof message == "function") {
                // Call the supplied function to get the message to log.
                logOutput = message();
            } else {
                logOutput = message;
            }

            let prefix = "";

            if (addColor) {
                prefix += `%c `;
                otherParams.unshift(color);
            }

            if (timestampMessages) {
                prefix += `[${new Date().toISOString()}] `;
            }

            target.apply(console, [`${prefix} ${logOutput}`, ...otherParams]);
        }
    }

    export function isError(): boolean {
        return getLogLevel() >= LogLevels.Error;
    }

    export function isWarning(): boolean {
        return getLogLevel() >= LogLevels.Warning;
    }

    export function isInfo(): boolean {
        return getLogLevel() >= LogLevels.Info;
    }

    export function isDebug(): boolean {
        return getLogLevel() >= LogLevels.Debug;
    }

    export function isTrace(): boolean {
        return getLogLevel() >= LogLevels.Trace;
    }
}
