import { CookieConsentCategory, loadScriptCookieSafe } from "./cookieConsent";

interface Config {
    tagId: string;
    userId?: string;
    sendPageView?: boolean;
}

/**
 * The Google tag (gtag.js) is a single tag to use a variety of Google products and services.
 * Instead of managing multiple tags for different Google product accounts, you can use the Google tag
 * across your entire website and connect the tag to multiple destinations.
 */
export function loadGoogleTagWithAnalytics(config: Config) {
    loadGoogleTagScript(config.tagId);

    window.gtag("js", new Date());

    // configure Google Analytics
    window.gtag("config", config.tagId, buildGoogleAnalyticsConfig(config));

    // configure consent
    window.gtag("consent", "default", {
        ad_storage: "denied",
        analytics_storage: "denied",
    });

    loadScriptCookieSafe(CookieConsentCategory.Performance, function () {
        window.gtag("consent", "update", { analytics_storage: "granted" });
    });

    loadScriptCookieSafe(CookieConsentCategory.Targeting, function () {
        window.gtag("consent", "update", { ad_storage: "granted" });
    });
}

/**
 * Configures Google Tag to load Google Ads scripts.
 * Depends on `loadGoogleTagWithAnalytics` being called first.
 * This is a separate function because it's not needed in every page.
 * It's only needed to report signups and subscriptions.
 */
export function loadGoogleAds() {
    if (window.gtag) {
        window.gtag("config", "AW-1070740767");
    }
}

function loadGoogleTagScript(tagId: string) {
    window.dataLayer = window.dataLayer || [];
    window.gtag = function gtag() {
        window.dataLayer.push(arguments);
    };

    const scriptElement = window.document.createElement("script");
    scriptElement.async = true;
    scriptElement.src = `https://www.googletagmanager.com/gtag/js?id=${tagId}`;
    const existingScriptElement = window.document.getElementsByTagName("script")[0];
    existingScriptElement.parentNode.insertBefore(scriptElement, existingScriptElement);
}

function buildGoogleAnalyticsConfig(config: Config) {
    const gaConfig: any = {
        // defaults to true, unless it's explicitly set to false
        send_page_view: config.sendPageView !== false,
    };

    if (config.userId) {
        gaConfig.user_id = config.userId;
    }

    return gaConfig;
}
