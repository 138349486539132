import {
    AnalyticsEventDefinition,
    AnalyticsEventDefinitions,
    AnalyticsEvents,
    AnalyticsProperties,
    AnalyticsPropertyValues,
} from "../enums/AnalyticsEvents";
import { ConsoleUtilities } from "./ConsoleUtilities";
import { Signal } from "./Signal";

export interface AnalyticPropertyValues {
    [key: string]: string | number | boolean | null;
}

// At present, it appears that this is necessary to be a class because the dispatchEvent is intended to be overridden
// TODO: Look at ways to make this more OOP, where we have a type-safe way of overriding the dispatchEvent method, and guaranteeing it is overridden
// eslint-disable-next-line @typescript-eslint/no-extraneous-class
export class Analytics {
    private static _isImpersonating: boolean = false;
    public static setIsImpersonating(newValue: boolean) {
        this._isImpersonating = newValue;
    }

    public static eventSignal = new Signal<(args: { name: string; properties: any }) => void>();

    public static filterSupportedProperties(
        eventDefinition: AnalyticsEventDefinition,
        properties: AnalyticPropertyValues | null,
    ): AnalyticPropertyValues {
        const supportedProperties: AnalyticPropertyValues = {};
        if (!properties) {
            return supportedProperties;
        }
        for (const key of Object.keys(properties)) {
            if (eventDefinition.properties.indexOf(key as AnalyticsProperties) >= 0) {
                // Convert boolean values to strings of "True" or "False".
                // This is consistent with how we do it on Android and iOS.
                if (typeof properties[key] === "boolean") {
                    supportedProperties[key] = properties[key]
                        ? AnalyticsPropertyValues.True
                        : AnalyticsPropertyValues.False;
                } else {
                    supportedProperties[key] = properties[key];
                }
            } else {
                ConsoleUtilities.warning(
                    `Analytics: Unrecognized analytics property '${key}' for event '${eventDefinition.name}'`,
                );
            }
        }
        return supportedProperties;
    }

    public static trackEvent(event: AnalyticsEvents, properties: AnalyticPropertyValues) {
        const eventDef: AnalyticsEventDefinition = AnalyticsEventDefinitions[event];
        const supportedProperties = this.filterSupportedProperties(eventDef, properties);

        Analytics.dispatchEvent(eventDef.name, supportedProperties);
    }

    // Clients can overwrite this method with their own metrics emission strategy,
    // or they can handle the event via `Analytics.eventSignal.addHandler(fn)`
    public static dispatchEvent(name: string, properties: any): void {
        if (this._isImpersonating) {
            // we don't want to record any analytics events when it's actually a staff member impersonating
            return;
        }
        this.eventSignal.dispatch({ name, properties });
    }
}
