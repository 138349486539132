import { Signal } from "./Signal";
import { AnyFunction } from "./UtilityTypes";

export class SignalBinding<ListenerType extends AnyFunction> {
    /**
     * Object that represents a binding between a Signal and a listener function.
     * <br />- <strong>This is an internal constructor and shouldn't be called by regular users.</strong>
     * <br />- inspired by Joa Ebert AS3 SignalBinding and Robert Penner's Slot classes.
     * @author Miller Medeiros
     * @constructor
     * @internal
     * @name SignalBinding
     * @param {Signal} signal Reference to Signal object that listener is currently bound to.
     * @param {Function} listener Handler function bound to the signal.
     * @param {boolean} isOnce If binding should be executed just once.
     * @param {Object} [listenerContext] Context on which listener will be executed (object that should represent the `this` variable inside listener function).
     * @param {Number} [priority] The priority level of the event listener. (default = 0).
     */
    constructor(
        signal: Signal<ListenerType>,
        listener: ListenerType,
        isOnce: boolean,
        listenerContext: any,
        priority: number = 0,
    ) {
        this._listener = listener;
        this._isOnce = isOnce;
        this.context = listenerContext;
        this._signal = signal;
        this.priority = priority || 0;
    }

    /**
     * Handler function bound to the signal.
     * @type Function
     * @private
     */
    private _listener: ListenerType | null;

    /**
     * If binding should be executed just once.
     * @type boolean
     * @private
     */
    private _isOnce: boolean;

    /**
     * Context on which listener will be executed (object that should represent the `this` variable inside listener function).
     * @memberOf SignalBinding.prototype
     * @name context
     * @type Object|undefined|null
     */
    public context: any;

    /**
     * Reference to Signal object that listener is currently bound to.
     * @type Signal
     * @private
     */
    private _signal: Signal<ListenerType> | null;

    /**
     * Listener priority
     * @type Number
     */
    public priority: number;

    /**
     * If binding is active and should be executed.
     * @type boolean
     */
    public active: boolean = true;

    /**
     * Call listener passing arbitrary parameters.
     * <p>If binding was added using `Signal.addOnce()` it will be automatically removed from signal dispatch queue, this method is used internally for the signal dispatch.</p>
     * @param {Array} [paramsArr] Array of parameters that should be passed to the listener
     * @return {*} Value returned by the listener.
     */
    public execute(paramsArr: Parameters<ListenerType>): any {
        let handlerReturn: any;

        if (this.active && !!this._listener) {
            handlerReturn = this._listener.apply(this.context, paramsArr);

            if (this._isOnce) {
                this.detach();
            }
        }

        return handlerReturn;
    }

    /**
     * Detach binding from signal.
     * - alias to: mySignal.remove(myBinding.getListener());
     * @return {Function|null} Handler function bound to the signal or `null` if binding was previously detached.
     */
    public detach(): ListenerType | null {
        if (this._signal && this._listener) {
            return this._signal.removeHandler(this._listener, this.context);
        }
        return null;
    }

    /**
     * @return {boolean} If SignalBinding will only be executed once.
     */
    public isOnce(): boolean {
        return this._isOnce;
    }

    /**
     * @return {Function} Handler function bound to the signal.
     */
    public getListener(): ListenerType | null {
        return this._listener;
    }

    /**
     * Delete instance properties
     */
    public destroy() {
        this._signal = null;
        this._listener = null;
        this.context = null;
    }
}
